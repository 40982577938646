import React from "react"
import { Link } from "gatsby"
import BlogStyles from "./header.module.scss"
import headerStyles from "./header.module.scss"

const HeaderHigiene = () => {
  return(
    <nav>
    <ul className={BlogStyles.navList}>
      <li>
        <Link
            className={headerStyles.navItem}
            activeClassName={headerStyles.activeNavItem}
          to="/desodorante"
        >
          Deodorant
        </Link>
      </li> 
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/papelhig"
        >
          Toilet Paper
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/sabonete"
        >
          Soap
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/pastadente"
        >
          Toothpaste
        </Link>
      </li>
    </ul>
  </nav>
  )
}
export default HeaderHigiene
